import React from "react";
import { useHistory } from "react-router-dom"; // Import useHistory from react-router-dom

const UploadButton = () => {
  const history = useHistory(); // Create a history object

  // Function to handle button click
  const handleUploadClick = () => {
    history.push('/fileupload'); // Navigate to the file upload page
  };

  return (
    <button
      className="btn btn-danger btn-block"
      onClick={handleUploadClick} // Set the click handler
    >
      Upload GEDCOM here
    </button>
  );
};

export default UploadButton; // Make sure to export UploadButton, not LogoutButton
