import configJson from "./auth_config.json";

export const getConfig = () => (
  {
    domain: configJson.domain,
    clientId: configJson.clientId,
    apiEndpoint: configJson.apiEndpoint,
    spouseapiEndpoint: configJson.spouseapiEndpoint,
    audience:configJson.audience,
    scope: configJson.scope,
  }
)