import React, { useState, useEffect } from 'react';
import { getConfig } from '../config';
import { useAuth0 } from '@auth0/auth0-react';

function FileUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [gedcomfile, setGedcomfile] = useState(null);
  const [probandnames, setProbandnames] = useState([]);
  const [selectedProband, setSelectedProband] = useState('');
  const [selectedProbandInfo, setSelectedProbandInfo] = useState(null); // Initialize as null
  const [loading, setLoading] = useState(false);
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [idToken, setIdToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const { apiEndpoint } = getConfig();

  // Define these state variables
  const [showProbandSpouseBox, setShowProbandSpouseBox] = useState(true);
  const [showSelectProbandBox, setShowSelectProbandBox] = useState(true);
  const [showCardSetButtons, setShowCardSetButtons] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  // Function to handle the "Get CardSet" button click
  const handleGetCardSet = () => {
    setShowProbandSpouseBox(false);
    setShowSelectProbandBox(false);
    setShowCardSetButtons(true);
    setShowConfirmButton(false);
  };

  const handleProbandSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedProband(selectedValue);
    console.log('Selected Value:', selectedValue); 
    console.log('type of selectedValue', typeof selectedValue);
  
    // Find the selected Proband's information using probandnames state
    const selectedProbandInfo = probandnames.find((item) => item.ID === parseInt(selectedValue));
    console.log('Selected Proband Info:', selectedProbandInfo);
  
    // Update the selectedProbandInfo state with the information
    setSelectedProbandInfo(selectedProbandInfo);
  };
  

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (selectedFile) {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', selectedFile);

      fetch(apiEndpoint, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data);
          setGedcomfile(data.guid);

          // Parse the JSON string and convert it into an array of objects
          const probandArray = JSON.parse(data.proband);

          // Now, you can map over the array to create the probandnames array
          const probandNamesArray = probandArray.map((item) => ({
            ID: item.ID,
            Name: `${item.First} ${item.Last} ${item.Birthdate}`,
            Spouse: {
              First: item.SpouseFirst,
              Last: item.SpouseLast,
              Birthdate: item.SpouseBirthdate,
            },
          }));

          setProbandnames(probandNamesArray);
          setLoading(false); // Set loading to false when the file upload is complete
          setShowConfirmButton(true); // Show the "Confirm Proband and Spouse" button
        })
        .catch((error) => {
          console.error('Error:', error);
          setLoading(false); // Handle errors and set loading to false
        });

    } else {
      alert('Please select a file to upload.');
    }
  };

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      setIdToken(claims.__raw);
    });

    getAccessTokenSilently().then((token) => {
      setAccessToken(token);
    });
  }, [getIdTokenClaims, getAccessTokenSilently]);

  return (
    <div>
      <h2>Auth0 Tokens</h2>
      <div>
        <strong>ID Token:</strong> <pre>{idToken}</pre>
      </div>
      <div>
        <strong>Access Token:</strong> <pre>{accessToken}</pre>
      </div>
      <h2>File Upload</h2>
      <input
        type="file"
        accept=".ged"
        onChange={handleFileChange}
      />
      <button onClick={handleUpload}>Upload</button>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {gedcomfile && (
            <div>
              <strong>File uploaded successfully. GUID:</strong> {gedcomfile}
            </div>
          )}

          {showSelectProbandBox && probandnames.length > 0 && (
            <div>
              <label>Select a Proband:</label>
              <select
                onChange={handleProbandSelect}
                value={selectedProband}
              >
                <option value=""> </option> {/* Empty option at the top */}
                {probandnames.map((item) => (
                  <option key={item.ID} value={item.ID}>
                    {item.Name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {showProbandSpouseBox && selectedProbandInfo && (
            <div>
              <label>Proband Spouse:</label>
              <input
                type="text"
                value={
                  `${
                    selectedProbandInfo.Spouse.First ? selectedProbandInfo.Spouse.First : 'No Spouse'
                  } ${
                    selectedProbandInfo.Spouse.Last ? selectedProbandInfo.Spouse.Last : ''
                  } ${
                    selectedProbandInfo.Spouse.Birthdate ? selectedProbandInfo.Spouse.Birthdate : ''
                  }`
                }
                readOnly
              />
            </div>
          )}

          {showConfirmButton && (
            <button onClick={handleGetCardSet}>Confirm Proband and Spouse</button>
          )}

          {/* Conditional rendering of CardSet buttons */}
          {showCardSetButtons && (
            <>
              <label>Select CardSet Type:</label>
              <div>
                <input type="radio" id="kids" name="cardSetType" value="kids" />
                <label htmlFor="kids">Kids</label>
              </div>
              <div>
                <input type="radio" id="grandkids" name="cardSetType" value="grandkids" />
                <label htmlFor="grandkids">Grandkids</label>
              </div>
              <div>
                <input type="radio" id="greatGrandkids" name="cardSetType" value="greatGrandkids" />
                <label htmlFor="greatGrandkids">Great-Grandkids</label>
              </div>
              <button>Get CardSet</button>
            </>
          )}
        </>
      )}

    </div>
  );
}

export default FileUpload;
